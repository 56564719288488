import { useState, useEffect } from "react";
//import Spinner from "./components/Spinner";
import "./App.css";
import Header from "./components/Header";
import EarlyArrival from "./components/EarlyArrival";
import LateStay from "./components/LateStay/LateStay";
import Admin from "./components/Admin";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import LLC from "./components/LLC/LLC";
import Help from "./components/Help/Help";
import Spinner from "./components/Spinner";
import { auth } from "./firebaseConfig";
import { getProfile } from "./services/authService";
import {
  getProfiles,
  getMessages,
  loadAccessKeys,
} from "./services/dataService";
import { globalData } from "./services/dataService";

function App() {
  const [module, setCurrentModule] = useState("");
  const [messages, setMessages] = useState("");
  const [profile, setProfile] = useState();
  const [user, setUser] = useState("pending");
  //const user = useAuthentication();
  /*const [user, setUser] = useState("pending");
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
  }, []);*/

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
  }, []);

  useEffect(() => {
    if (!user) {
      setProfile();
      setCurrentModule("home");
    }
    if (user) {
      getProfile(user.uid, setProfile);
      setCurrentModule("home");
    }
  }, [user]);

  useEffect(() => {
    if (profile) {
      getMessages(profile.orgCode, true, true).then(setMessages);
      getProfiles(profile.orgCode).then((p) => (globalData.profiles = p));
      loadAccessKeys(profile.orgCode);
    }
  }, [profile]);

  return (
    <>
      <div className="system-banner">
        Please note, beginning December 1, 2024 this application will only be
        available at&nbsp;
        <a href="https://app.partnerportal.info">app.partnerportal.info</a>.
        Please update any bookmarks or links accordingly.
      </div>
      {user === "pending" && <Spinner />}
      {user !== "pending" && !user && <Login setProfile={setProfile} />}
      {user !== "pending" && user && profile && (
        <div className="app">
          <Header
            profile={profile}
            user={user}
            setModule={setCurrentModule}
            currentModule={module}
          />
          {module === "home" && (
            <Dashboard profile={profile} messages={messages} />
          )}
          {module === "earlyArrival" && (
            <EarlyArrival user={user} profile={profile} />
          )}
          {module === "lateStay" && <LateStay user={user} profile={profile} />}
          {module === "admin" && <Admin profile={profile} />}
          {module === "llc" && <LLC profile={profile} />}
          {module === "help" && <Help profile={profile} />}
        </div>
      )}
    </>
  );
}

export default App;
