export default function HelpWhatsNew() {
  return (
    <div className="panel">
      <div className="panel-header">
        <h4>What's New</h4>
      </div>
      <div className="panel-body">
        <ul>
          <li>
            <b>2024-07-01</b>
          </li>
          <ul>
            <li>
              Early Arrival & Late Stay Group Owners can now be changed by
              admins.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2024-04-19</b>
          </li>
          <ul>
            <li>An issue with Late Stay Group editing has been resolved.</li>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2024-04-12</b>
          </li>
          <ul>
            <li>New Late Stay Module!</li>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2024-04-08</b>
          </li>
          <ul>
            <li>
              Bug Fix: Dashboard messages now properly honor the Start and End
              Dates when dislaying.
            </li>
            <li>Help Module sub-pages have been reordered.</li>
            <li>
              Support Tickets are now only accessible by organization
              administrators.
            </li>
            <li>
              Additional layout improvements across the Dashboard and Admin
              Module.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2024-01-01</b>
          </li>
          <ul>
            <li>New "Help" section provides ability to:</li>
            <ul>
              <li>
                Submit Support Tickets for 1) requesting assistance, 2)
                reporting system issues, and 3) suggestions new features.
              </li>
              <li>
                Review Resource documents which provide information on how to
                complete various tasks.
              </li>
              <li>Review system updates in one place with historical notes.</li>
            </ul>
            <li>
              You can no longer delete an Early Arrival Group until all members
              have been removed.
            </li>
            <li>Members list now shows which group the member is a part of.</li>
            <li>
              Early Arrivals can now be processed directly from the Partner
              Portal into StarRez from the Term in Admin.
            </li>
            <ul>
              <li>
                Processing Early Arrivals currently creates an Entry Note with
                Type "Early Arrival" and the Note Date set to the requested
                arrival date.
              </li>
              <li>
                Future updates will provide options for updating check-in dates
                or setting an entry custom field directly.
              </li>
            </ul>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2023-12-31</b>
          </li>
          <ul>
            <li>
              System emails now come from{" "}
              <a href="mailto:hello@partnerportal.info">
                hello@partnerportal.info
              </a>
              .{" "}
              <i>
                We recommend adding this email to your address book to ensure
                you receive all future emails.
              </i>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <b>2023-12-29</b>
          </li>
          <ul>
            <li>Users can now reset their password from the login screen.</li>
          </ul>
        </ul>
      </div>
    </div>
  );
}
